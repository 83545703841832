<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="External Users"
      subtitle="Browse and Manage your users!"
      :badge="this.total"
    />
    <v-row class="mb-1" justify="space-between">
      <v-col cols="12" md="auto" class="d-flex align-center">
        <v-sheet
          key="sheets-status"
          elevation="2"
          height="32"
          rounded
          class="d-inline-flex align-center justify-center pl-3 pr-1"
        >
          <h4 class="ma-0 text--secondary fs-14px" style="flex: none">Show:</h4>
          <v-btn-toggle
            class="ml-3"
            v-model="options.activeStatus"
            mandatory
            color="info"
            dense
            group
            style="height: 32px"
          >
            <v-btn
              :value="2"
              color="white"
              class="mx-0 mr-2"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-user-check fa-swap-opacity mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">Active Users</span>
            </v-btn>
            <v-btn
              :value="3"
              color="white"
              class="mx-0"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-user-slash mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">Suspended Users</span>
            </v-btn>
            <v-divider vertical inset class="mx-2"></v-divider>
            <v-btn
              :value="1"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-users mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">Everyone</span>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="auto">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <export-excel :options="options"></export-excel>
          <export-pdf :options="options"></export-pdf>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getData()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 users-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.firstName`]="{ item }">
        <div class="d-inline-flex">
          <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
        </div>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <a :href="'mailto:' + item.email">{{ item.email }}</a>
      </template>

      <template v-slot:[`item.roles`]="{ item }">
        <v-row style="margin: 5px 0px">
          <v-chip
            style="font-size: 11px; font-weight: 600"
            v-for="role in item.roles"
            :key="role.name"
            label
            x-small
            color="deep-purple"
            text-color="white"
            :ripple="false"
            link
            @click="filterByRole(role)"
          >
            {{ role.name }}
          </v-chip>
          <v-chip
            v-if="item.isSuperUser"
            class="ml-2"
            style="font-size: 11px; font-weight: 600"
            pill
            x-small
            color="amber"
            text-color="black"
            :ripple="false"
          >
            <i class="fad fa-crown mr-2"></i>Super User
          </v-chip>
        </v-row>
      </template>

      <template v-slot:[`item.isSuspended`]="{ item }">
        <v-chip v-if="item.isSuspended" x-small color="error"> Suspend </v-chip>
        <v-chip v-else x-small color="success"> Active </v-chip>
      </template>

      <template v-slot:[`item.joinDate`]="{ item }">
        <dater :date="item.joinDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              v-if="loggedUser.isSuperUser && !item.isSuperUser && $has(perms.DNAUsers.Update)"
              @click="makeSuperUser(item)"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="amber--text text--darken-2">fas fa-crown</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="amber--text text--darken-3">
                  Make as Super User
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-else-if="loggedUser.isSuperUser && item.isSuperUser && $has(perms.DNAUsers.Update)"
              @click="unmakeSuperUser(item)"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="red--text">fas fa-crown</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Remove as Super User</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2" v-if="loggedUser.isSuperUser"></v-divider>

            <v-list-item @click="update(item)" v-if="$has(perms.DNAUsers.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit User</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="suspend(item)" v-if="$has(perms.DNAUsers.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">{{
                  item.isSuspended ? "far fa-check" : "far fa-ban"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.isSuspended ? "Activate" : "Suspend"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template #loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <edit-user @save="onUserSaved" @close="onClientClosed" ref="editUser"></edit-user>
  </v-container>
</template>

<script>
import ApiService from "../../../Admin/services/StaffService";
import ExternalUsersApiService from "../services/external-users-service.js";
import PageTitle from "../../../Shared/components/PageTitle.vue";
import UserAvatar from "../../../Shared/components/UserAvatar.vue";
import EditUser from "../components/EditUser.vue";
import userHeader from "../config/tables/user.header";
import perms from "../../../../plugins/permissions";
import exportExcel from "../components/ExportExcel.vue";
import exportPdf from "../components/ExportPdf.vue";
import FilterManager from "../../../Shared/components/FilterManager/FilterManager.vue";
import { ExternalRoleFilterSettings } from "../../../Shared/components/FilterManager/FilterSettings";

export default {
  components: { PageTitle, UserAvatar, EditUser, exportPdf, exportExcel, FilterManager },
  data() {
    return {
      perms,
      loggedUser: null,
      storageKey: "Users",
      usersTimerId: null,
      paramId: "",
      entities: [],
      selected: {},
      total: 0,
      selectedFilters: [],
      search: "",
      valid: false,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["joinDate"],
        sortDesc: [true],
        RoleIds: [],
        activeStatus: 2,
      },
      roles: [],
      loadingStates: {
        table: false,
      },
      headers: userHeader,
      defaultHeaders: [],
      breadCrumb: [
        {
          text: "Users",
          disabled: false,
          href: "/users",
        },
      ],
      actions: {
        sortBy: -1,
        sortDesc: false,
      },
    };
  },
  computed: {
    sortableColumns() {
      return this.headers.filter((elm) => elm.sortable);
    },
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.loggedUser = this.$store.getters.user;
    this.defaultHeaders = this.headers;

    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$refs.mainSearch.focus();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var externalRoleFilterSettings = new ExternalRoleFilterSettings();

      this.selectedFilters = [externalRoleFilterSettings];
    },
    filterByRole(role) {
      this.options.RoleIds.push(role.id);
    },
    onUserSaved(userData) {
      this.updateArr(this.entities, userData);
      this.total = this.entities.length;
      this.loadingStates.table = false;
    },
    getData() {
      this.$backToTop(0, document.querySelector(".users-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      ExternalUsersApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
          if (this.$route.params.id) {
            this.view(this.entities.find((elm) => elm.id == this.$route.params.id));
          }
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    view(user) {
      setTimeout(() => {
        const path = `/external-users/${user.id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editUser.open(user.id, false);
      });
    },
    update(user) {
      setTimeout(() => {
        const path = `/external-users/${user.id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editUser.open(user.id, true);
      });
    },
    rowClicked(row) {
      this.view(row);
    },
    onClientClosed() {
      const path = `/external-users`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    suspend(item) {
      this.$dialog
        .warning({
          text: item.isSuspended
            ? "Are you sure you want to active this user?"
            : `Are you sure you want to suspend this user?`,
          title: item.isSuspended ? "Active User" : `Suspend User?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.suspend(item.id, !item.isSuspended)
                  .then((resp) => {
                    this.updateArr(this.entities, resp.data);
                    this.$dialog.notify.success(
                      `User ${item.isSuspended ? "active" : "suspend"} successfully`,
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    makeSuperUser(item) {
      this.$dialog
        .warning({
          text: `Are you sure you want to make <b><u>${item.firstName} ${item.lastName}</u></b> as a Super User?`,
          title: `Make as Super User!`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return ApiService.updateSuperUser(item.id, true)
                  .then((resp) => {
                    this.updateArr(this.entities, resp.data);
                    this.$dialog.notify.success(
                      `<b><u>${item.firstName} ${item.lastName}</u></b> is now a Super User!`,
                      {
                        position: "top-right",
                        timeout: 5000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    unmakeSuperUser(item) {
      this.$dialog
        .error({
          text: `Do you want to remove <b><u>${item.firstName} ${item.lastName}</u></b> from being a Super User?`,
          title: `Remove as Super User!`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.updateSuperUser(item.id, false)
                  .then((resp) => {
                    this.updateArr(this.entities, resp.data);
                    this.$dialog.notify.success(
                      `<b><u>${item.firstName} ${item.lastName}</u></b> is no longer a Super User!`,
                      {
                        position: "top-right",
                        timeout: 5000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    getDataDebounced() {
      if (this.usersTimerId == null) {
        this.usersTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.usersTimerId);

      // delay new call 400ms
      this.usersTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        this.$log(">> watch options");
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, newOptions.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>
